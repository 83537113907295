import { ReactNode, useContext } from 'react'

import { useIsTouchDevice } from '@lib/helpers'
import { SearchContext } from '@lib/search-context'
import { SiteContext } from '@lib/site-context'

import DropdownButton from './dropdown-button'

interface MegaNavigationButtonProps {
  id: string
  children?: ReactNode
  className?: string
}

const MegaNavigationButton = ({
  id,
  children,
  className,
}: MegaNavigationButtonProps) => {
  const isTouchDevice = useIsTouchDevice()
  const {
    megaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
    toggleMegaNavigation,
  } = useContext(SiteContext)
  const { toggleSearchDrawer } = useContext(SearchContext)

  const isActive = megaNavigation?.activeId === id

  const handleClick = () => {
    if (isTouchDevice) {
      toggleSearchDrawer(false)
      toggleMegaNavigation(!isActive ? true : 'toggle', id)
    }
  }

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }

      toggleSearchDrawer(false)
      toggleMegaNavigation(true, id)
    }
  }

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300)
      )
    }
  }

  return (
    <DropdownButton
      id={`meganav-${id}`}
      isActive={isActive}
      className={className}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </DropdownButton>
  )
}

export default MegaNavigationButton
