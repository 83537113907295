import cx from 'classnames'
import { ReactNode } from 'react'

interface DropdownButtonProps {
  id: string
  isActive: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const DropdownButton = ({
  id,
  isActive,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: DropdownButtonProps) => {
  return (
    <button
      aria-expanded={isActive}
      aria-controls={`dropdown-button-${id}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(
        'group inline-flex items-center',
        isActive && 'opacity-60',
        className
      )}
    >
      {children}
    </button>
  )
}

export default DropdownButton
