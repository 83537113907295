import { useCallback, useContext } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import Hamburger from './hamburger'

interface MobileMenuToggleProps {
  className?: string
}

const MobileMenuToggle = ({ className }: MobileMenuToggleProps) => {
  const strings = useContext(StringsContext)
  const { mobileMenu, toggleMobileMenu } = useContext(SiteContext)

  const handleClick = useCallback(() => {
    toggleMobileMenu(!mobileMenu.isOpen)
  }, [mobileMenu, toggleMobileMenu])

  return (
    <button
      onClick={handleClick}
      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
      aria-controls="mobile-nav"
      aria-label={strings.mobileMenuLabel}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit rounded-full',
        {
          'text-pageText': mobileMenu.isOpen,
        },
        className
      )}
    >
      <Hamburger isOpened={mobileMenu.isOpen} className="color-pageText" />
    </button>
  )
}

export default MobileMenuToggle
