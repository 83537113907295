import {
  type SanityCombinedListingConfiguration,
  type SanityCombinedListingProductFragment,
} from '@data/sanity/queries/types/product'
import { isNumeric } from '@lib/helpers'
import { type OptionalSize, getIsSmallerThan } from '@lib/size'
import { findCombinedListingProducts } from './product'
import { filterProductsBySize } from './size'
import { type DropdownOptionWithColor } from './types'

/**
 * Gets dropdown input options from products.
 */
export const getCombinedListingProductDropdownOptions = (
  products: SanityCombinedListingProductFragment[],
  size: string | null,
  noSelectionTitle?: string
) => {
  const productOptions: DropdownOptionWithColor[] = []

  if (noSelectionTitle) {
    productOptions.push({
      value: '',
      title: noSelectionTitle,
    })
  }

  products.filter(filterProductsBySize(size)).forEach((product) => {
    productOptions.push({
      value: `${product.productID}`,
      title: product.title,
      // Add color value, if product combined listing type is frame
      color:
        product.combinedListingProductType === 'frame'
          ? product.combinedListingFrameColor
          : undefined,
    })
  })

  return productOptions
}

/**
 * Determines whether combined listing consists of an empty frame product.
 */
export const getIsEmptyFrame = (
  combinedListingProducts: SanityCombinedListingProductFragment[]
) => {
  const frameId = combinedListingProducts.find(
    (product) => product.combinedListingProductType === 'frame'
  )?.productID
  const glassId = combinedListingProducts.find(
    (product) => product.combinedListingProductType === 'glass'
  )?.productID
  const backboardId = combinedListingProducts.find(
    (product) => product.combinedListingProductType === 'backboard'
  )?.productID
  const passepartoutId = combinedListingProducts.find(
    (product) => product.combinedListingProductType === 'passepartout'
  )?.productID
  const spacerId = combinedListingProducts.find(
    (product) => product.combinedListingProductType === 'spacer'
  )?.productID

  return (
    combinedListingProducts.length === 1 &&
    !!frameId &&
    !glassId &&
    !backboardId &&
    !passepartoutId &&
    !spacerId
  )
}

/**
 * Determines whether combined listing product size is large format.
 */
export const getIsCombinedListingLargeFormat = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  productIds: number[],
  size: string
) => {
  // Get combined listing dimensions from size string
  const frameSize = size.split('x').map((dimension) => {
    const dimensionString = dimension.replaceAll(',', '.')
    return isNumeric(dimensionString) ? Number(dimensionString) : undefined
  }) as OptionalSize

  if (!frameSize[0] || !frameSize[1]) {
    return false
  }

  // Go through each combined listing product
  const products = findCombinedListingProducts(
    combinedListingConfiguration,
    productIds
  )

  return products.reduce((isLargeFormat, product) => {
    if (isLargeFormat || !product.combinedListingLargeFormatSettings) {
      return isLargeFormat
    }

    // Get large format settings from glass and compare with frame size
    const { dimentsion1, dimentsion2 } =
      product.combinedListingLargeFormatSettings
    const isSmallerThan = getIsSmallerThan(frameSize, [
      dimentsion1,
      dimentsion2,
    ])

    return !isSmallerThan
  }, false)
}
