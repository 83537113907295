import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type SanityCombinedListingProductFragment } from '@data/sanity/queries/types/product'
import { type DropdownOption } from '@lib/helpers'
import {
  type CombinedListingProductType,
  getCombinedListingProductDefaultValues,
  getCombinedListingProductDropdownOptions,
  getCombinedListingProductsBySize,
  getIsEmptyFrame,
  ProductCombinedListingContext,
} from '@lib/product/combined-listing'
import { StringsContext } from '@lib/strings-context'

import InputDropdown from '@components/input-dropdown/with-managed-state'
import InputField from '@components/input-field/with-managed-state'
import NewLineToBr from '@components/new-line-to-br'
import Tooltip from '@components/tooltip'
import SmallGraphic from './small-graphic'
import Icon from '@components/icon'

interface CombinedListingProductSelectProps {
  onMainProductChange: (product: SanityCombinedListingProductFragment) => void
  frameColor?: string
}

const CombinedListingProductSelect = ({
  onMainProductChange,
  frameColor,
}: CombinedListingProductSelectProps) => {
  const strings = useContext(StringsContext)
  const {
    combinedListingSettings,
    combinedListingConfiguration,
    combinedListingMainProductId,
    combinedListingActiveProducts,
    combinedListingActiveSize,
    combinedListingActivePassepartoutHoleSize,
    addCombinedListingProduct,
    clearCombinedListingProductType,
    setCombinedListingSize,
    setCombinedListingPassepartoutHoleSize,
  } = useContext(ProductCombinedListingContext)

  const [passepartoutHoleSize, setPassepartoutHoleSize] = useState('')

  const isDefaultSelectionLoadedRef = useRef(false)
  const isPassepartoutHoleSizeLoadedRef = useRef(false)

  // Product IDs for dropdown input values
  const glassId = useMemo(
    () =>
      combinedListingActiveProducts?.find(
        (product) => product.combinedListingProductType === 'glass'
      )?.productID,
    [combinedListingActiveProducts]
  )
  const backboardId = useMemo(
    () =>
      combinedListingActiveProducts?.find(
        (product) => product.combinedListingProductType === 'backboard'
      )?.productID,
    [combinedListingActiveProducts]
  )
  const passepartoutId = useMemo(
    () =>
      combinedListingActiveProducts?.find(
        (product) => product.combinedListingProductType === 'passepartout'
      )?.productID,
    [combinedListingActiveProducts]
  )
  const spacerId = useMemo(
    () =>
      combinedListingActiveProducts?.find(
        (product) => product.combinedListingProductType === 'spacer'
      )?.productID,
    [combinedListingActiveProducts]
  )

  // Options for dropdown inputs
  const frameOptions = useMemo(
    () =>
      getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.frame ?? [],
        null
      ),
    [combinedListingConfiguration]
  )
  const sizeOptions = useMemo<DropdownOption[]>(() => {
    const combinedListing = getCombinedListingProductsBySize(
      combinedListingConfiguration?.frame ?? [],
      combinedListingConfiguration?.glass ?? [],
      combinedListingConfiguration?.backboard ?? [],
      combinedListingConfiguration?.passepartout ?? [],
      combinedListingConfiguration?.spacer ?? []
    )

    return Object.keys(combinedListing).map((size) => ({
      value: size,
      title: size,
    }))
  }, [combinedListingConfiguration])
  const glassOptions = useMemo(
    () =>
      getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.glass ?? [],
        combinedListingActiveSize ?? null,
        strings.combinedListingNoGlass
      ),
    [combinedListingActiveSize, combinedListingConfiguration, strings]
  )
  const backboardOptions = useMemo(
    () =>
      getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.backboard ?? [],
        combinedListingActiveSize ?? null,
        strings.combinedListingNoBackboard
      ),
    [combinedListingActiveSize, combinedListingConfiguration, strings]
  )
  const passepartoutOptions = useMemo(
    () =>
      getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.passepartout ?? [],
        combinedListingActiveSize ?? null,
        strings.combinedListingNoPassepartout
      ),
    [combinedListingActiveSize, combinedListingConfiguration, strings]
  )
  const spacerOptions = useMemo(
    () =>
      getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.spacer ?? [],
        combinedListingActiveSize ?? null,
        strings.combinedListingNoSpacer
      ),
    [combinedListingActiveSize, combinedListingConfiguration, strings]
  )

  const isEmptyFrame = useMemo(
    () => getIsEmptyFrame(combinedListingActiveProducts ?? []),
    [combinedListingActiveProducts]
  )

  // Load passepartout hole size into internal state variable
  useEffect(() => {
    if (
      typeof combinedListingActivePassepartoutHoleSize === 'undefined' ||
      isPassepartoutHoleSizeLoadedRef.current
    ) {
      return
    }

    setPassepartoutHoleSize(combinedListingActivePassepartoutHoleSize ?? '')
    isPassepartoutHoleSizeLoadedRef.current = true
  }, [combinedListingActivePassepartoutHoleSize])

  /**
   * Validates value for product type and deselects it, if it's invalid.
   */
  const validateProductType = useCallback(
    (type: CombinedListingProductType, size: string, value?: number) => {
      if (!value) {
        return
      }

      const options = getCombinedListingProductDropdownOptions(
        combinedListingConfiguration?.[type] ?? [],
        size
      )
      const isValid = options.some((option) => option.value === `${value}`)

      // Deselect products that don't have a matching size
      if (!isValid) {
        clearCombinedListingProductType(type)
      }
    },
    [clearCombinedListingProductType, combinedListingConfiguration]
  )

  /**
   * Selects default glass, backboard, passepartout and spacer products.
   */
  const selectDefaults = useCallback(
    (size: string) => {
      // Select default values only once
      if (
        !combinedListingSettings ||
        !combinedListingConfiguration ||
        isDefaultSelectionLoadedRef.current
      ) {
        return
      }

      isDefaultSelectionLoadedRef.current = true

      // Stop if any product is already selected
      if (glassId || backboardId || passepartoutId || spacerId) {
        return
      }

      const defaultValues = getCombinedListingProductDefaultValues(
        combinedListingSettings,
        combinedListingConfiguration,
        size
      )
      const defaultProductIds = [
        defaultValues.glassProductId,
        defaultValues.backboardProductId,
        defaultValues.passepartoutProductId,
        defaultValues.spacerProductId,
      ].filter(Boolean) as number[]

      defaultProductIds.forEach((productId) => {
        addCombinedListingProduct(productId)
      })
    },
    [
      addCombinedListingProduct,
      backboardId,
      combinedListingConfiguration,
      combinedListingSettings,
      glassId,
      passepartoutId,
      spacerId,
    ]
  )

  const handleFrameChange = useCallback(
    (newProductId: string) => {
      if (!newProductId) {
        return
      }

      const newProduct = combinedListingConfiguration?.frame?.find(
        (product) => product.productID === Number(newProductId)
      )

      if (!newProduct) {
        return
      }

      onMainProductChange(newProduct)
    },
    [combinedListingConfiguration, onMainProductChange]
  )

  const handleSizeChange = useCallback(
    (newSize: string) => {
      if (!newSize) {
        return
      }

      setCombinedListingSize(newSize)

      validateProductType('glass', newSize, glassId)
      validateProductType('backboard', newSize, backboardId)
      validateProductType('passepartout', newSize, passepartoutId)
      validateProductType('spacer', newSize, spacerId)

      selectDefaults(newSize)
    },
    [
      backboardId,
      glassId,
      passepartoutId,
      selectDefaults,
      setCombinedListingSize,
      spacerId,
      validateProductType,
    ]
  )

  const handleGlassChange = useCallback(
    (newProductId: string) => {
      isDefaultSelectionLoadedRef.current = true

      if (!newProductId) {
        clearCombinedListingProductType('glass')
        return
      }

      addCombinedListingProduct(Number(newProductId))
    },
    [addCombinedListingProduct, clearCombinedListingProductType]
  )

  const handleBackboardChange = useCallback(
    (newProductId: string) => {
      isDefaultSelectionLoadedRef.current = true

      if (!newProductId) {
        clearCombinedListingProductType('backboard')
        return
      }

      addCombinedListingProduct(Number(newProductId))
    },
    [addCombinedListingProduct, clearCombinedListingProductType]
  )

  const handlePassepartoutChange = useCallback(
    (newProductId: string) => {
      isDefaultSelectionLoadedRef.current = true

      if (!newProductId) {
        clearCombinedListingProductType('passepartout')
        setCombinedListingPassepartoutHoleSize(null)
        setPassepartoutHoleSize('')
        return
      }

      addCombinedListingProduct(Number(newProductId))
    },
    [
      addCombinedListingProduct,
      clearCombinedListingProductType,
      setCombinedListingPassepartoutHoleSize,
      setPassepartoutHoleSize,
    ]
  )

  const handlePassepartoutHoleSizeChange = useCallback(
    (newPassepartoutHoleSize: string) => {
      isDefaultSelectionLoadedRef.current = true
      setPassepartoutHoleSize(newPassepartoutHoleSize)

      if (!newPassepartoutHoleSize) {
        setCombinedListingPassepartoutHoleSize(null)
        return
      }

      setCombinedListingPassepartoutHoleSize(newPassepartoutHoleSize)
    },
    [setCombinedListingPassepartoutHoleSize, setPassepartoutHoleSize]
  )

  const handleSpacerChange = useCallback(
    (newProductId: string) => {
      if (!newProductId) {
        clearCombinedListingProductType('spacer')
        return
      }

      addCombinedListingProduct(Number(newProductId))
    },
    [addCombinedListingProduct, clearCombinedListingProductType]
  )

  return (
    <div className="max-w-sm w-full space-y-5">
      <label className="font-medium mb-2">
        {frameColor
          ? `${strings.combinedListingFrameColor} ${frameColor}`
          : strings.combinedListingFrame}
      </label>
      <div className="flex flex-wrap gap-x-2 gap-y-1.5">
        {frameOptions.map((frameOption) => (
          <SmallGraphic
            key={frameOption.value}
            selected={
              combinedListingMainProductId === Number(frameOption.value)
            }
            backgroundColor={frameOption.color}
            onClick={() => handleFrameChange(frameOption.value)}
          />
        ))}
      </div>

      <InputDropdown
        value={combinedListingActiveSize ?? ''}
        setValue={handleSizeChange}
        label={strings.combinedListingSize}
        placeholder={strings.combinedListingSizePlaceholder}
        options={sizeOptions}
      />

      {!!combinedListingActiveSize && (
        <>
          {isEmptyFrame && (
            <div className="inline-block px-4 py-2 rounded-md bg-gray-700 text-pageBG">
              {strings.combinedListingEmptyFrame}
            </div>
          )}

          <InputDropdown
            value={glassId ? `${glassId}` : ''}
            setValue={handleGlassChange}
            label={strings.combinedListingGlass}
            options={glassOptions}
          />

          <InputDropdown
            value={backboardId ? `${backboardId}` : ''}
            setValue={handleBackboardChange}
            label={strings.combinedListingBackboard}
            options={backboardOptions}
          />

          <InputDropdown
            value={passepartoutId ? `${passepartoutId}` : ''}
            setValue={handlePassepartoutChange}
            label={strings.combinedListingPassepartout}
            options={passepartoutOptions}
          />

          {!!passepartoutId && (
            <InputField
              type="text"
              value={passepartoutHoleSize}
              setValue={handlePassepartoutHoleSizeChange}
              label={
                <>
                  {strings.combinedListingPassepartoutHoleSize}
                  {!!strings.combinedListingPassepartoutHoleSizeTooltip && (
                    <Tooltip
                      content={
                        <p>
                          <NewLineToBr>
                            {strings.combinedListingPassepartoutHoleSizeTooltip}
                          </NewLineToBr>
                        </p>
                      }
                      placement="auto"
                      trigger={['click', 'hover']}
                      className="inline-block ml-1"
                    >
                      <Icon
                        id="passepartout-hole-size-tooltip-icon"
                        name="QuestionMark"
                        title={strings.combinedListingPassepartoutHoleSize}
                      />
                    </Tooltip>
                  )}
                </>
              }
              placeholder={
                strings.combinedListingPassepartoutHoleSizePlaceholder
              }
            />
          )}

          <InputDropdown
            value={spacerId ? `${spacerId}` : ''}
            setValue={handleSpacerChange}
            label={strings.combinedListingSpacer}
            options={spacerOptions}
          />
        </>
      )}
    </div>
  )
}

export default CombinedListingProductSelect
