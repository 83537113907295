import cx from 'classnames'
import NextLink from 'next/link'
import { useContext, useEffect, useState } from 'react'

import { type SanityProductCategoryResult } from '@data/sanity/queries/types/product'
import { getLinkPageUrl } from '@lib/routes'
import { useDebouncedSearchProductCategories } from '@lib/search'
import { SearchContext } from '@lib/search-context'
import { StringsContext } from '@lib/strings-context'

interface SearchProductCategoryResultsProps {
  className?: string
}

const SearchProductCategoryResults = ({
  className,
}: SearchProductCategoryResultsProps) => {
  const { querySuggestionValue, toggleSearchDrawer } = useContext(SearchContext)
  const strings = useContext(StringsContext)

  const [isLoading, setIsLoading] = useState(false)
  const [productCategories, setProductCategories] = useState<
    SanityProductCategoryResult[]
  >([])

  const debouncedLoadResults = useDebouncedSearchProductCategories()

  useEffect(() => {
    // When query is missing or empty, clear preview results
    if (!querySuggestionValue) {
      setProductCategories([])
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    debouncedLoadResults(querySuggestionValue, (newProductCategories) => {
      setProductCategories(newProductCategories)
      setIsLoading(false)
    })
  }, [debouncedLoadResults, querySuggestionValue])

  return (
    <div className={cx(className)}>
      {isLoading && (
        <p className="py-4 text-center uppercase text-xs">
          {strings.searchingText}
        </p>
      )}

      {!isLoading && productCategories.length === 0 && (
        <p className="py-4 text-center uppercase text-xs">
          {strings.noResultsText}
        </p>
      )}

      {!isLoading && productCategories.length > 0 && (
        <>
          {productCategories?.map((productCategory) => (
            <div key={productCategory._id} className="p-4">
              <NextLink
                href={getLinkPageUrl(
                  'productCategory',
                  productCategory.slug?.current,
                  {
                    query: querySuggestionValue,
                  }
                )}
                onClick={() => {
                  toggleSearchDrawer(false)
                }}
              >
                {/* TODO: Content and styling. */}
                <h3>{productCategory.title}</h3>
                <p>{productCategory.matchingProductCount} products</p>
              </NextLink>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default SearchProductCategoryResults
