import { useContext, useEffect, useRef, useState } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityHeaderSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { CartContext } from '@lib/cart/context'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'

import MegaNavigation from '@blocks/navigation/mega-navigation'
import Menu from '@blocks/navigation/menu'
import SearchDrawer from '@blocks/navigation/search-drawer'
import CartToggle from '@components/cart-toggle'
import PromoBar from '../promo-bar'
import AccountButton from './account-button'
import Logo from './logo'
import MobileMenu from './mobile-menu'
import MobileMenuToggle from './mobile-menu-toggle'
import SearchField from './search-field'

export interface HeaderSizeValues {
  height: number
}

type HeaderProps = Pick<SanitySiteFragment, 'promoSettings'> &
  SanityHeaderSettings & {
    isInView: boolean
    logo?: SanityImageFragment
    invertedLogo?: SanityImageFragment
    onResize?: (newValues: HeaderSizeValues) => void
  }

const Header = ({
  promoSettings,
  menuDesktopLeft,
  menuMobilePrimary,
  menuMobileSecondary,
  logo,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { toggleMegaNavigation } = useContext(SiteContext)
  const { toggleCart } = useContext(CartContext)
  const { user } = useContext(UserContext)

  const [isLoaded, setIsLoaded] = useState(false)

  const headerHeightRef = useRef<number>()
  const headerRef = useRef<HTMLDivElement | null>(null)
  const headerRectangle = useResizeObserver({
    ref: headerRef,
  })

  // Wait for intersection observer to load, then show header
  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }
  }, [isLoaded])

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (
      headerRectangle.height &&
      headerHeightRef.current !== headerRectangle.height
    ) {
      headerHeightRef.current = headerRectangle.height

      if (onResize) {
        onResize({
          height: headerRectangle.height,
        })
      }
    }
  }, [headerRectangle, isLoaded, onResize])

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      {promoSettings?.enabled && (
        <PromoBar
          enabled={promoSettings.enabled}
          display={promoSettings.display}
          text={promoSettings.text}
          link={promoSettings.link}
        />
      )}

      <MobileMenu
        menuMobilePrimary={menuMobilePrimary}
        menuMobileSecondary={menuMobileSecondary}
      />

      <header
        ref={headerRef}
        className="sticky top-0 inset-x-0 z-50 text-header-text transition-colors duration-300 bg-header-bg"
      >
        <nav className="relative z-60 border-b border-divider">
          <div className="container flex items-center justify-between py-4 lg:py-5 gap-x-5 lg:gap-x-16">
            <div className="flex items-center gap-x-16">
              <Logo logo={logo} />

              {!!menuDesktopLeft?.items && (
                <Menu
                  id={menuDesktopLeft.slug.current}
                  items={menuDesktopLeft.items}
                  onClick={() => toggleMegaNavigation(false)}
                  useMegaNav
                  isHeaderDesktopMenu
                  className="hidden lg:flex"
                />
              )}
            </div>

            <div className="flex items-center gap-x-3 sm:gap-x-4">
              <SearchField />

              <AccountButton />

              {!!user?.isLoggedIn && (
                <CartToggle
                  onClick={() => toggleCart(true)}
                  className="mr-2 lg:mr-1"
                  showCartCount
                  showCartIcon
                />
              )}

              <MobileMenuToggle className="lg:hidden" />
            </div>
          </div>
        </nav>

        <MegaNavigation
          items={[...(menuDesktopLeft?.items ?? [])]}
          headerHeight={headerRectangle?.height ?? 0}
        />
        <SearchDrawer headerHeight={headerRectangle?.height ?? 0} />
      </header>
    </>
  )
}

export default Header
